.Login {
  display: grid;
}

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
    justify-content: center;
    margin: auto;
  }

  .Login form {
    margin: 0 auto;
    max-width: 450px;
  }

  .login-header {
    font-size: xx-large;
    margin-bottom: 50px;
  }
}

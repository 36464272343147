@media only screen and (max-width: 767px) {
  /* For mobile: */
  .d-col-s-1 {
    width: 8.33%;
  }
  .d-col-s-2 {
    width: 16.66%;
  }
  .d-col-s-3 {
    width: 25%;
  }
  .d-col-s-4 {
    width: 33.33%;
  }
  .d-col-s-5 {
    width: 41.66%;
  }
  .d-col-s-6 {
    width: 50%;
  }
  .d-col-s-7 {
    width: 58.33%;
  }
  .d-col-s-8 {
    width: 66.66%;
  }
  .d-col-s-9 {
    width: 75%;
  }
  .d-col-s-10 {
    width: 83.33%;
  }
  .d-col-s-11 {
    width: 91.66%;
  }
  .d-col-s-12 {
    width: 100%;
  }

  .d-col-s-12 {
    height: calc(100vh - 120px);
  }

  .dispatch-s-container {
    height: calc(100vh - 120px);
    margin-top: 60px;
    padding-right: 15px;
    padding-bottom: 15px;
  }

  .dispatch-flex-s-row {
    display: flex;
    flex-direction: row;
  }

  .dispatch-flex-grow-s-row {
    display: flex;
    flex-direction: row;
    flex: 1;
  }

  .dispatch-flex-s-column {
    display: flex;
    flex-direction: column;
  }

  .dispatch-flex-grow-s-column {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .hide-on-mobile {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  /* For tablet: */
  .d-col-1 {
    width: 8.33%;
  }
  .d-col-2 {
    width: 16.66%;
  }
  .d-col-3 {
    width: 25%;
  }
  .d-col-4 {
    width: 33.33%;
  }
  .d-col-5 {
    width: 41.66%;
  }
  .d-col-6 {
    width: 50%;
  }
  .d-col-7 {
    width: 58.33%;
  }
  .d-col-8 {
    width: 66.66%;
  }
  .d-col-9 {
    width: 75%;
  }
  .d-col-10 {
    width: 83.33%;
  }
  .d-col-11 {
    width: 91.66%;
  }
  .d-col-12 {
    width: 100%;
  }

  .dispatch-container {
    height: 100%;
    padding-right: 15px;
    padding-bottom: 15px;
  }

  .dispatch-flex-row {
    display: flex;
    flex-direction: row;
  }

  .dispatch-flex-grow-row {
    display: flex;
    flex-direction: row;
    flex: 1;
  }

  .dispatch-flex-column {
    display: flex;
    flex-direction: column;
  }

  .dispatch-flex-grow-column {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.dispatch-container {
  height: 92vh;
  padding-right: 15px;
  padding-bottom: 15px;
}

.dispatch-header-title {
  text-align: left;
  font-size: large;
  font-weight: 600;
  margin-left: 20px;
}

.dispatch-map {
  height: 100%;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 20px;
}

.dispatch-map-full-size {
  height: 100%;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 20px;
}

.alc-view {
  height: 100%;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  margin-top: 10px;
  margin-left: 20px;
  padding-left: 10px;
  background-color: white;
}

.alc-sort-title {
  z-index: 11;
  height: 7%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: large;
  font-weight: 700;
  margin: 10px;
}

.alc-sort-options {
  margin-bottom: 10px;
  margin-left: 20px;
}

.alc-data-title-child {
  color: rgb(185, 184, 184);
  text-align: left;
  padding-left: 15px;
  margin-left: calc(10% + 38px);
  width: 35%;
}

.alc-data-title-status {
  color: rgb(185, 184, 184);
  text-align: left;
  padding-left: 15px;
  margin-left: 15px;
}

.alc-view-button {
  padding: 0;
  margin-left: 25px;
  width: 10%;
}

.alc-child-details {
  text-align: left;
  margin-left: 15px;
  padding-left: 15px;
  width: 40%;
}

.alc-child-name {
  font-size: large;
}

.alc-child-age {
  font-size: small;
  color: grey;
}

.alc-child-status {
  text-align: left;
}

button {
  outline-style: none !important;
  outline: none !important;
}

.alc-sort-toggle-off {
  background-color: #979797;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  outline: none;
}

.alc-sort-toggle-on {
  background-color: #3245f3;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  outline: none;
}

.statistics-toggle {
  text-align: right;
  flex: 1;
}

.lost-child-stats {
  height: 15vh;
  margin-top: 15px;
}

.lost-child-stats-box {
  background-color: white;
  border: 15px solid #edeff2;
  border-radius: 25px;
  height: 15vh;
  padding: 15px;
}

.lost-child-stats-box-title {
  text-align: left;
}

.legend-container {
  border-style: solid;
  border-width: 1px;
  border-color: #282c34;
  border-radius: 5px;
  background-color: white;
  margin-left: 5px;
  font-size: small;
}

.green-minute-text {
  color: green;
}

.yellow-minute-text {
  color: rgb(218, 172, 24);
}

.red-minute-text {
  color: red;
}

.legend-name {
  font-size: x-large;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 10px;
}

.legend-account-type {
  font-size: medium;
  text-align: left;
}

.legend-text {
  border-top-left-radius: 4px;
  font-size: small;
  min-width: 200px;
  text-align: left;
  margin-bottom: 10px;
}

.alc-list {
  z-index: 10;
  overflow-y: scroll;
  color: #282c34;
  background-color: white;
  height: 100%;
  width: 100%;
  font-weight: 700;
  text-align: left;
  padding-bottom: 3px;
  padding-left: 5px;
  margin-left: 0px;
  margin-right: 15px;
}

.alc-scroll-list {
  overflow-y: scroll;
  max-height: 215px;
  padding: 15px 0;
}

hr {
  border: 0;
  clear: both;
  display: block;
  width: 90%;
  height: 1px;
  margin-top: 5px;
  margin-bottom: 4px;
  border-top: 1px solid rgb(228, 226, 226);
}
